<template>
  <div>
    <a-row :gutter="[16, 16]" class="mt-4">
      <!-- <a-col :span="6">
        <div class="border rounded px-3 pb-2">
          <h1 class="text-primary">
            <animated-number
              :value="187"
              :formatValue="formatValue"
              :duration="1000"
            />
          </h1>
          <h4>Jarak Ke Sekolah &lt; 1 km</h4>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="border rounded px-3 pb-2">
          <h1 class="text-warning">
            <animated-number
              :value="13"
              :formatValue="formatValue"
              :duration="1000"
            />
          </h1>
          <h4>Jarak Ke Sekolah &gt; 1 km</h4>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="border rounded px-3 pb-2">
          <h1 class="text-info">
            <animated-number
              :value="68"
              :formatValue="formatValue"
              :duration="1000"
            />
          </h1>
          <h4>Asal Sekolah Dalam Kota</h4>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="border rounded px-3 pb-2">
          <h1 class="text-danger">
            <animated-number
              :value="142"
              :formatValue="formatValue"
              :duration="1000"
            />
          </h1>
          <h4>Asal Sekolah Luar Kota</h4>
        </div>
      </a-col> -->
      <a-col :span="24">
        <div class="border rounded p-3">
          <h4 class="mb-4">Pemetaan Tempat Tinggal Murid</h4>
          <div style="height: 400px; width: 100%">
            <location-cluster
              :locations="dataLocation.data_map_murid"
              tooltipType="jarak_ke_sekolah"
              :options="clusterOptions"
            />
          </div>
        </div>
      </a-col>
      <!-- <a-col :span="8">
        <div class="border rounded p-3">
          <h4 class="mb-4">Kota Asal Sekolah</h4>
          <div class="table-responsive">
            <table class="table table-borderless text-gray-6 mb-0">
              <tbody>
                <tr v-for="item in mappedKota" :key="item.title">
                  <td class="pl-0 font-weight-bold">
                    <p>{{ item.title }}</p>
                  </td>
                  <td class="text-right">
                    <p class="font-weight-bold">{{ item.total }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </a-col> -->
      <a-col :span="12">
        <div class="border rounded p-3">
          <h4 class="mb-4">Jarak Tempat Tinggal Ke Sekolah</h4>
          <pie-chart
            :labels="mappedJarakTempuh"
            :data="dataLocation.data_chart_jarak_tempat_tinggal_ke_sekolah"
            :options="jarakTempuhDataSet.options"
          />
        </div>
      </a-col>
      <a-col :span="12">
        <div class="border rounded p-3">
          <h4 class="mb-4">Waktu Tempuh Ke Sekolah</h4>
          <pie-chart
            :labels="mappedWaktuTempuh"
            :data="dataLocation.data_chart_waktu_tempuh_ke_sekolah"
            :options="waktuTempuhDataSet.options"
          />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// import AnimatedNumber from 'animated-number-vue'
import { jarakTempuhList, waktuTempuhList } from '@/helpers/listsPpdbSelect'
const LocationCluster = () => import('@/components/app/shared/LocationCluster')
const waktuTempuhDataSet = {
  data: [23, 82, 12, 65, 23, 125],
  options: {
    maintainAspectRatio: false,
  },
}
const kota = ['Jakarta', 'Bekasi', 'Bogor', 'Tanggerang', 'Banten']
const PieChart = () => import('@/components/app/shared/ChartJS/Pie')
const locations = [
  {
    latitude: -6.313286169605539,
    longitude: 106.91139155076502,
    jarak: 13.8,
    waktu_tempuh_ke_sekolah: 2860,
    address: 'Balai Uji Standar Karantina Ikan Pengendalian Mutu Dan Keamanan Hasil Perikanan, Jalan Harapan I, RW 05, Setu, Cipayung, East Jakarta, Jakarta Special Capital Region, 13890, Indonesia',
  },
  {
    latitude: -6.313976234195372,
    longitude: 106.9093661408388,
    jarak: 13.2,
    waktu_tempuh_ke_sekolah: 2760,
    address: 'Jalan Raya Mabes Hankkam, RW 05, Setu, Cipayung, East Jakarta, Jakarta Special Capital Region, 13890, Indonesia',
  },
  {
    latitude: -6.314516140615398,
    longitude: 106.91053561044883,
    jarak: 13.5,
    waktu_tempuh_ke_sekolah: 2810,
    address: 'Jalan Puskesmas, RW 03, Setu, Cipayung, East Jakarta, Jakarta Special Capital Region, 13890, Indonesia',
  },
  {
    latitude: -6.265561,
    longitude: 106.784327,
    jarak: 10.9,
    waktu_tempuh_ke_sekolah: 2516,
    address: 'Jalan Metro Pondok Indah Kav. IV, RT.1/RW.16, Pd. Pinang, Kec. Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12310, Indonesia',
  },
  {
    latitude: -6.274966,
    longitude: 106.773429,
    jarak: 14.2,
    waktu_tempuh_ke_sekolah: 2887,
    address: 'Jl. Ciputat Raya, Indonesia',
  },
  {
    latitude: -6.201186,
    longitude: 106.842276,
    jarak: 7.3,
    waktu_tempuh_ke_sekolah: 1673,
    address: 'Jl. Pangeran Diponegoro, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta, Indonesia',
  },
  {
    latitude: -6.2348,
    longitude: 106.729195,
    jarak: 18.5,
    waktu_tempuh_ke_sekolah: 2073,
    address: 'Jl. Caplin Satu, Kec. Larangan, Kota Tangerang, Banten 15154, Indonesia',
  },
  {
    latitude: -6.15525,
    longitude: 106.608518,
    jarak: 23.5,
    waktu_tempuh_ke_sekolah: 2573,
    address: 'Periuk Jaya, Periuk, Tangerang City, Banten, Indonesia',
  },
  {
    latitude: -6.169474,
    longitude: 106.626721,
    jarak: 20.9,
    waktu_tempuh_ke_sekolah: 2373,
    address: 'Jl. Ks. Tubun No.10, RT.001/RW.001, Ps. Baru, Kec. Karawaci, Kota Tangerang, Banten 15112, Indonesia',
  },
]
export default {
  components: {
    PieChart,
    // AnimatedNumber,
    LocationCluster,
  },
  props: {
    dataLocation: {
      type: Object,
      required: true,
    },
    tahunMasuk: {
      type: String,
    },
  },
  data() {
    return {
      waktuTempuhDataSet,
      jarakTempuhList,
      waktuTempuhList,
      kota,
      clusterOptions: {},
      locations,
    }
  },
  methods: {
    handleMenuClick(e) {
      this.schoolYearActive = e.key
    },
    formatValue(value) {
      return `${Number(value).toFixed(0)}`
    },
    toDetail(key, value) {
      this.$router.push({ name: 'Detail Student Candidate Admin', query: { [key]: value, tahun_masuk: this.tahunMasuk } })
    },
  },
  computed: {
    institusi() {
      return this.$store.state.master.institusi
    },
    mappedKota() {
      return this.kota.map(row => {
        return {
          title: row,
          total: Math.floor(Math.random() * (200 - 5 + 1)) + 5,
        }
      })
    },
    mappedJarakTempuh() {
      return this.jarakTempuhList.map(row => row.title)
    },
    mappedWaktuTempuh() {
      return this.waktuTempuhList.map(row => row.title)
    },
    jarakTempuhDataSet() {
      return {
        data: [14, 58, 123, 23, 13, 60],
        options: {
          maintainAspectRatio: false,
          onClick: (e, arr) => {
            const index = arr[0]?._index
            console.log(index)
            if (!isNaN(index)) {
              const jarakTempuhTitle = jarakTempuhList[index].title
              this.$router.push({ name: 'Detail Student Candidate Admin', query: { geolokasi_tempat_tinggal: jarakTempuhTitle, tahun_masuk: this.tahunMasuk } })
            }
          },
        },
      }
    },
  },
}
</script>

<style>
</style>
